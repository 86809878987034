import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
declare var pg: any;

@Component({
  selector: 'stat-tile-widget',
  templateUrl: './stat-tile-widget.component.html',
  styleUrls: ['./stat-tile-widget.component.scss']
})
export class StatTileWidgetComponent implements OnInit {
  

  constructor() { }

  ngOnInit() {
  }

}
