// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAZU6Dti-c9qjq_S4mEO_r1DszpMiDAiUY",
    authDomain: "athena-a3909.firebaseapp.com",
    databaseURL: "https://athena-a3909.firebaseio.com",
    projectId: "athena-a3909",
    storageBucket: "athena-a3909.appspot.com",
    messagingSenderId: "357057169874"
  }
};
